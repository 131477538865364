import { TLocale } from '../infra/locale';

export const COPYTRADING_LINKS: Record<TLocale, string> = {
  en: 'https://copy.tiger.trade/index-en.html',
  ru: 'https://copy.tiger.trade/index-ru.html',
  ua: 'https://copy.tiger.trade/index-ua.html',
  // es: 'https://copy.tiger.trade/index-en.html',
};

export const EXCHANGE_LINKS: Record<TLocale, string> = {
  en: 'https://tiger.exchange/',
  ru: 'https://tiger.exchange/',
  ua: 'https://tiger.exchange/',
  // es: 'https://tiger.exchange/',
};

export const TERMINAL_MAC_OS_LINKS: Record<TLocale, string> = {
  en: 'https://macos.tiger.trade/',
  ru: 'https://macos.tiger.trade/',
  ua: 'https://macos.tiger.trade/',
  // es: 'https://macos.tiger.trade/',
};

export const BROKER_LINKS: Record<TLocale, string> = {
  en: 'https://broker.tiger.trade/',
  ru: 'https://broker.tiger.trade/ru/',
  ua: 'https://broker.tiger.trade/',
  // es: 'https://broker.tiger.trade/',
};

export const SUPPORT_LINKS: Record<TLocale, string> = {
  en: 'https://support.tiger.trade/v/english/',
  ru: 'https://support.tiger.trade/',
  ua: 'https://support.tiger.trade/v/english/',
  // es: 'https://support.tiger.trade/v/english/',
};

export const SUPPORT_BROKER_LINKS: Record<TLocale, string> = {
  en: 'https://broker-docs.tiger.trade/v/english-2/',
  ru: 'https://broker-docs.tiger.trade/',
  ua: 'https://broker-docs.tiger.trade/v/english-2/',
  // es: 'https://broker-docs.tiger.trade/v/english-2/',
};

export const LOGIN_WINDOWS_LINKS = {
  en: '/terminal/login',
  ru: '/ru/terminal/login',
  ua: '/ua/terminal/login',
  // es: '/es/terminal/login',
};

export const LOGIN_MACOS_LINKS = {
  en: 'https://macos.tiger.trade/login',
  ru: 'https://macos.tiger.trade/login',
  ua: 'https://macos.tiger.trade/login',
  // es: 'https://macos.tiger.trade/login',
};

export const LOGIN_BROKER_LINKS = {
  en: 'https://account.tiger.trade/',
  ru: 'https://account.tiger.trade/',
  ua: 'https://account.tiger.trade/',
  // es: 'https://account.tiger.trade/',
};
