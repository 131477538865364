import { TLocale } from '../infra/locale';

export const PARTNERS_GAME_FI_LINKS: Record<TLocale, string> = {
  ru: 'https://www.binance.com/ru/event/gamefi_hackathon',
  en: 'https://www.binance.com/en/event/gamefi_hackathon',
  ua: 'https://www.binance.com/uk-UA/event/gamefi_hackathon',
  // es: 'https://www.binance.com/es/event/gamefi_hackathon',
};

export const PARTNERS_FIDE_LINKS: Record<TLocale, string> = {
  ru: 'https://fide.com/news/1197',
  en: 'https://fide.com/news/1197',
  ua: 'https://fide.com/news/1197',
  // es: 'https://fide.com/news/1197',
};

export const PARTNERS_PARTNERSHIP_LINKS: Record<TLocale, string> = {
  ru: 'https://www.binance.com/ru-UA/blog',
  en: 'https://www.binance.com/en/blog/open-platform/binance-broker-spotlight-inside-tigertrades-success-story-421499824684902871',
  ua: 'https://www.binance.com/uk-UA/blog',
  // es: 'https://www.binance.com/es-ES/blog',
};
