export const LANGUAGES = [
  { name: 'header_en', value: 'en' },
  // { name: 'header_es', value: 'es' },
  { name: 'header_ru', value: 'ru' },
  { name: 'header_ua', value: 'ua' },
  /*
  { name: 'header_ar', value: 'ar' },
  { name: 'header_pt', value: 'pt' },
  { name: 'header_fr', value: 'fr' },
  { name: 'header_de', value: 'de' },
  { name: 'header_it', value: 'it' },
  { name: 'header_tr', value: 'tr' },
  { name: 'header_hi', value: 'hi' },
  { name: 'header_vi', value: 'vi' },
  */
] as const;

export const LOGIN_LINKS = [{ href: '/terminal/login', name: 'login', internal: true }];

export const EMAIL_RGX = /^\S+@\S+\.\S+$/;

export const INFO_LINKS = [
  /*
  { href: 'google.com', name: 'link_payingLicenses', internal: false },
  { href: 'google.com', name: 'link_payingMethod', internal: false },
   */
  // { href: '/public-offer', name: 'link_publicOffer', internal: true },
];

export const POLICY_LINKS = [
  // { href: '/using-cookies', name: 'link_usingCookies', internal: true },
  { href: '/terminal/privacy-policy', name: 'link_privacyPolicy', internal: true },
  { href: '/terminal/terms-of-service', name: 'link_termsOfUse', internal: true },
  // { href: '/public-offer', name: 'link_publicOffer_short', internal: true },
  {
    href: '/terminal/end-user-license-agreement',
    name: 'link_endUserLicenseAgreement',
    internal: true,
  },
];

export const HOST_NAME = typeof window !== 'undefined' && window.location.origin;

export const DOWNLOAD_LINK = {
  /**
   * @see https://gitlab.tiger.black/tigertrade/ci/helm/values/get-url/-/blob/30e09aa341ba3e4a423ccdd9443aaab07054297f/values.yaml#L24
   */
  href: `https://get-url.tiger.trade/TigerTrade.exe?t=${Date.now()}`,
  name: 'download_link',
};

export const currencies = {
  en: 'USD',
  ru: 'RUB',
};

export const PASSWORD_RGX = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&-])[A-Za-z\d@$!%*#?&-_\s]{8,}$/;

export const TABLE_LICENSES = [
  {
    id: 1,
    title: 'prices_table_title_1',
    subTitle: 'prices_table_subTitle_1',
    cryptoIcon: { icon: 'include', text: '' },
    fullIcon: 'include',
    playerIcon: 'include',
  },
  {
    id: 2,
    title: 'prices_table_title_2',
    subTitle: 'prices_table_subTitle_2',
    subTitle2: 'prices_table_subTitle_2_1',
    subTitleItem: 'prices_table_subTitleItem',
    cryptoIcon: { icon: 'not-include', text: '' },
    fullIcon: 'include',
    playerIcon: 'include',
    more: true,
  },
  {
    id: 3,
    title: 'prices_table_title_3',
    subTitle: 'prices_table_subTitle_3',
    cryptoIcon: { icon: 'include-crypto', text: 'prices_table_type_1' },
    fullIcon: 'include',
    playerIcon: 'not-include',
  },
  {
    id: 4,
    title: 'prices_table_title_4',
    subTitle: 'prices_table_subTitle_4',
    cryptoIcon: { icon: 'not-include', text: '' },
    fullIcon: 'include',
    playerIcon: 'include',
  },
  {
    id: 5,
    title: 'prices_table_title_5',
    subTitle: 'prices_table_subTitle_5',
    cryptoIcon: { icon: 'include-crypto', text: 'prices_table_type_1' },
    fullIcon: 'include',
    playerIcon: 'not-include',
  },
  {
    id: 6,
    title: 'prices_table_title_6',
    subTitle: 'prices_table_subTitle_6',
    cryptoIcon: { icon: 'include-crypto', text: 'prices_table_type_2' },
    fullIcon: 'include',
    playerIcon: 'not-include',
  },
];

export const TABLE_LICENSES_MOBILE = [
  {
    id: 1,
    title: 'prices_table_title_1',
    subTitle: 'prices_table_subTitle_1',
    cryptoIcon: { icon: 'include', text: 'prices_table_crypto' },
    fullIcon: { icon: 'include', text: 'prices_table_full' },
    playerIcon: { icon: 'include', text: 'prices_table_player' },
  },
  {
    id: 2,
    title: 'prices_table_title_2',
    subTitle: 'prices_table_subTitle_2',
    subTitle2: 'prices_table_subTitle_2_1',
    subTitleItem: 'prices_table_subTitleItem',
    cryptoIcon: { icon: 'not-include', text: 'prices_table_crypto' },
    fullIcon: { icon: 'include', text: 'prices_table_full' },
    playerIcon: { icon: 'include', text: 'prices_table_player' },
    more: true,
  },
  {
    id: 3,
    title: 'prices_table_title_3',
    subTitle: 'prices_table_subTitle_3',
    cryptoIcon: { icon: 'include-crypto', text: 'prices_table_type_1' },
    fullIcon: { icon: 'include', text: 'prices_table_full' },
    playerIcon: { icon: 'not-include', text: 'prices_table_player' },
  },
  {
    id: 4,
    title: 'prices_table_title_4',
    subTitle: 'prices_table_subTitle_4',
    cryptoIcon: { icon: 'not-include', text: 'prices_table_crypto' },
    fullIcon: { icon: 'include', text: 'prices_table_full' },
    playerIcon: { icon: 'include', text: 'prices_table_player' },
  },
  {
    id: 5,
    title: 'prices_table_title_5',
    subTitle: 'prices_table_subTitle_5',
    cryptoIcon: { icon: 'include-crypto', text: 'prices_table_type_1' },
    fullIcon: { icon: 'include', text: 'prices_table_full' },
    playerIcon: { icon: 'not-include', text: 'prices_table_player' },
  },
  {
    id: 6,
    title: 'prices_table_title_6',
    subTitle: 'prices_table_subTitle_6',
    cryptoIcon: { icon: 'include-crypto', text: 'prices_table_type_2' },
    fullIcon: { icon: 'include', text: 'prices_table_full' },
    playerIcon: { icon: 'not-include', text: 'prices_table_player' },
  },
];
